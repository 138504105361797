/* @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"); */
div#app {
    margin: 0px;
}

@font-face {
    font-family: 'Bukra-Bold';
    src: url('../fonts/29ltbukra-bold-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Bukra-Regular';
    src: url('../fonts/29ltbukra-regular-webfont.woff') format('woff');
}

.font-Bukra {
    font-family: 'Bukra-Regular';
}

nav.navbar.navbar-light.bg-light {
    background: #92edce !important;
}

.logoutBtn {
    text-decoration: none !important;
    color: #013321 !important
}

.cover-overlay {
    width: 100%;
    height: 100vh;
    background: #0000006b;
    position: absolute;
    top: 0;
}

.pop-moal {
    z-index: 2000;
    background: #fff;
    padding: 40px 20px;
    position: relative;
    margin: 0 auto;
    top: 30vh;
    width: 500px;
    border-radius: 5px;
}

.pop-moal h4 {
    font-size: 20px;
    font-weight: 600;
    color: #013321;
    font-family: 'Bukra-Bold' !important;
    margin-bottom: 20px
}

.pop-moal .icon {
    color: #ff9d00;
    margin-right: 10px;
}

.pop-moal a {
    color: #013321;
    font-family: 'Bukra-Regular' !important;
    text-decoration: none;
    margin-top: 20px;
}

.pop-moal a:hover {
    text-decoration: underline;
}

p {
    font-family: 'Bukra-Regular';
}

a.navbar-brand {
    min-width: 420px;
    display: inline-flex;
}

.navbar-brand small {
    font-size: 16px;
    color: #013321;
    margin-top: 8px;
    margin-left: 10px;
}

.group-modal .form-select:focus {
    box-shadow: unset;
}

.arco-modal-content .arco-btn.arco-btn-primary.arco-btn-size-small.arco-btn-shape-square {
    display: none;
}

.arco-space-item .arco-btn.arco-btn-outline.arco-btn-size-default.arco-btn-shape-square {
    display: none;
}

/* ------------------- Communicate page style ------------------ */
.head-section .title h3 {
    color: #59bd9b;
    font-weight: bold;
    letter-spacing: 0.3px;
}

.head-section .title p {
    color: #404040;
    font-weight: 600;
}

.tabs-section .nav-link.active {
    color: #103d2d !important;
    border: 1px solid #a7f2d4 !important;
    border-radius: 20px;
    background-color: #eafcf5 !important;
    padding: 6px 18px !important;
}

.tabs-section .nav-link {
    color: #103d2d !important;
    padding: 6px 18px !important;
    transition: unset !important;
    -webkit-transition: unset !important;
    -moz-transition: unset !important;
    -ms-transition: unset !important;
    -o-transition: unset !important;
}

.tabs-section .card-box {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 14px;
    /* border-radius: 5px; */
    border-left: 5px solid #92edce;
    /* border-top: 2px solid #e2e8f0; */
    align-items: center;
}

.tabs-section .card-box .card-info h5 {
    color: #4aba92;
    text-transform: capitalize;
    cursor: pointer;
}

.tabs-section .card-box .card-info .label-title {
    background: #f3f3f3;
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}

.action-btn {
    border: 2px solid #f1f3f7;
    border-radius: 5px;
    padding: 7px 13px;
    font-size: 18px;
    cursor: pointer;
}

.copy-action {
    color: #043423;
}

.delete-action {
    color: #ff0000;
}

@media (max-width : 767px) {
    .card-box {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 15px;
    }
}

button.btn-Vault,
input.btn.btn-primary.btn-Vault,
.mt-3.text-end button.btn.btn-primary,
a.btn-Vault {
    background: #92edce;
    border: #92edce;
    border: 1px solid #92edce;
    color: #000;
}

button.btn-Vault-sendTest {
    background: none;
    border: 1px solid #92edce;
    color: #000;
}

button.btn-Vault:hover,
input.btn.btn-primary.btn-Vault:hover,
.mt-3.text-end button.btn.btn-primary:hover,
button.btn-Vault-sendTest:hover,
a.btn-Vault:hover {
    background: #013321;
    border: 1px solid #013321;
    color: #fff;

}

button.btn.btn-primary.btn-gray {
    background: #ededed;
    border: #ededed;
    color: #000;
    border: 1px solid #ededed;
}

button.btn.btn-primary.btn-gray:hover {
    background: #dad8d8;
    border: #dad8d8;
    border: 1px solid #dad8d8;
    color: #000;
}

.row.navBuilder {
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 10px 20px 10px 7px;
}

button.btn.btn-secondary.btn-Vault.dropdown-toggle {
    margin-right: 10px;
}

.btn-right {
    float: right;
}

.titleInput {
    border-radius: 5px;
    padding: 0px 10px;
    margin-right: 4px;
}

.btn-close {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -o-transform: scale(0.6);
}

/* -----------------  Delete confirm box style ---------------- */

.swal2-icon.swal2-warning {
    border-color: #fb0000 !important;
    color: #fb0000 !important;
    margin: 1.5rem auto 0em !important;
    width: 4em !important;
    height: 4em !important;
}

.swal2-default-outline:focus {
    box-shadow: unset !important;
}

.swal2-icon-content {
    font-size: 3em !important;
}

.icon-calendar:before {
    content: "\63";
}

.icon-link {
    transform: rotate(45deg);
}

.arco-space-item:has(.arco-icon-eye) {
    display: none;
}

.template-subject {
    margin-top: 5px;
    margin-right: 10px;
}

h3.templates {
    color: #59bd9b;
    font-weight: 700;
}

.create-btn.py-1.mt-1.mb-4 span {
    background: #fafafa;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 6px 8px -6px #00000069;
    color: #060606;
    float: right;
    font-size: 24px;
    height: 30px;
    line-height: 1.1;
    text-align: center;
    width: 30px !important;
    margin-bottom: 30px;
}

.form-select:focus,
.form-control:focus {
    border-color: #92edce !important;
    outline: 0;
    box-shadow: unset !important;
    font-weight: 500;
}

.go2072408551 {
    max-width: unset !important;
}

.arco-upload-list-reupload-icon {
    display: none !important;
}

.add-location-btn:active {
    border: none !important;
}

@media (max-width: 767px) {
    .container {
        max-width: 100% !important;
    }

    a.navbar-brand {
        width: 82%;
        display: inline-flex;
        margin: 0px;
        min-width: auto;
    }

    .navbar-brand small {
        white-space: normal
    }

    .slick-prev {
        left: 2% !important;
        z-index: 10;
    }

    .slick-next {
        right: 2% !important;
        z-index: 10;
    }
}

.modal-custom-header {
    padding: 0.8rem 1rem 0.3rem !important;
}

#success-modal .modal-header {
    border-bottom: none !important;
}

#success-modal .fa-check {
    font-size: 28px;
    font-weight: bolder;
}

#success-modal p {
    font-size: 12px;
    color: #696969;
    /* color: #c5c5c5; */
}

#success-modal .check-icon {
    width: 100px;
    height: auto;
}

#success-modal .check-icon img {
    transform: rotate(5deg);
}

#success-modal .modal-dialog {
    max-width: 440px;
}

@media (max-width: 575px) {
    #success-modal .modal-dialog {
        max-width: 100% !important;
    }
}

#success-modal .modal-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding-top: 0;
}

@media (max-width: 375px) {
    #success-message {
        font-size: 16px;
    }

    #success-modal p {
        font-size: 10px;
    }
}

.head-section .title h3.dark_title,
.dark_title {
    color: #204D3C;
    font-size: 22px;
    font-family: 'Bukra-bold';
}